/*******************************
         Site Overrides
*******************************/

.ui.secondary.pointing.menu .dropdown.item:active,
.ui.secondary.pointing.menu .link.item:active,
.ui.secondary.pointing.menu a.item:active {
  border-color: transparent;
}

/* Vertical Alternative Pointing */

.ui.vertical.pointing-alt.menu {
  overflow: hidden;
}

.ui.vertical.pointing-alt.menu .item {
  border-bottom: none;
  border-right: none;
  border-left-style: solid;
  border-left-color: transparent;
  border-radius: 0em !important;
  margin: @secondaryVerticalPointingAltItemMargin;
  border-left-width: @verticalSecondaryPointingAltBorderWidth;
}

/* Vertical Active */
.ui.vertical.pointing-alt.menu .active.item {
  border-color: @secondaryPointingActiveBorderColor;
}
