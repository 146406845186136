/*******************************
        User Overrides
*******************************/

.ui.inline.dropdown {
  text-decoration: @linkUnderline;
}

.ui.inline.dropdown:hover {
  color: @linkHoverColor;
}
