/*******************************
         Site Overrides
*******************************/

a.ui.textlink {
  color: @textColor;
}

a.ui.textlink:hover {
  color: @linkHoverColor;
}

/* Mobile Only Hide */
@media only screen and (max-width: @largestMobileScreen) {
  [class*="mobile hidden"],
  [class*="tablet only"]:not(.mobile),
  [class*="computer only"]:not(.mobile),
  [class*="large screen only"]:not(.mobile),
  [class*="widescreen only"]:not(.mobile) {
    display: none !important;
  }
}

/* Tablet Only Hide */
@media only screen and (min-width: @tabletBreakpoint) and (max-width: @largestTabletScreen) {
  [class*="mobile only"]:not(.tablet),
  [class*="tablet hidden"],
  [class*="computer only"]:not(.tablet),
  [class*="large screen only"]:not(.mobile),
  [class*="widescreen only"]:not(.mobile) {
    display: none !important;
  }
}

/* Computer Only Hide */
@media only screen and (min-width: @computerBreakpoint) and (max-width: @largestSmallMonitor) {
  [class*="mobile only"]:not(.computer),
  [class*="tablet only"]:not(.computer),
  [class*="computer hidden"],
  [class*="large screen only"]:not(.mobile),
  [class*="widescreen only"]:not(.mobile) {
    display: none !important;
  }
}

/* Large Screen Only Hide */
@media only screen and (min-width: @largeMonitorBreakpoint) and (max-width: @largestLargeMonitor) {
  [class*="mobile only"]:not(.computer),
  [class*="tablet only"]:not(.computer),
  [class*="large screen hidden"],
  [class*="widescreen only"]:not(.mobile) {
    display: none !important;
  }
}

/* Widescreen Only Hide */
@media only screen and (min-width: @widescreenMonitorBreakpoint) {
  [class*="mobile only"]:not(.computer),
  [class*="tablet only"]:not(.computer),
  [class*="widescreen hidden"] {
    display: none !important;
  }
}
