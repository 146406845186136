/*******************************
         Site Overrides
*******************************/

.ui[class*="floated"].header {
  margin-top: 0em;
  margin-bottom: 0em;
}

.ui[class*="right floated"].header {
  margin-right: 0em;
}
