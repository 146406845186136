/*******************************
         Site Overrides
*******************************/

.ui.striped.grid > .row:nth-child(2n) {
  background-color: @stripedBackground;
}

.ui.tabular.grid  {
  margin-left: -@tableSpacing;
  margin-right: -@tableSpacing;
}

.ui.tabular.grid > .column:not(.row),
.ui.tabular.grid > .row > .column {
  padding-left: @tableSpacing;
  padding-right: @tableSpacing;
}

.ui.tabular.grid > * {
  padding-left: @tableSpacing;
  padding-right: @tableSpacing;
}
