/*******************************
    User Variable Overrides
*******************************/

.ui.items > .item .meta.primary {
  color: @headerColor;
}

.ui.items > .item .description.secondary {
  color: @metaColor;
}

.ui.items > .item .meta.primary + .meta:not(&.primary) {
  margin-top: @descriptionDistance;
}

.ui.items > .item .header > a:not(.ui) {
  color: @headerLinkColor;
}
.ui.items > .item .header > a:not(.ui):hover {
  color: @headerLinkHoverColor;
}

.ui.items > .item .meta.primary > a:not(.ui) {
  color: @headerLinkColor;
}
.ui.items > .item .meta.primary > a:not(.ui):hover {
  color: @metaLinkHoverColor;
}

.ui.items > .item.big {
  font-size: @relativeMassive;
}

.ui.items > .item.big > .content > .header:not(.ui) {
  font-size: unit((36 / 16), rem);
  line-height: 1em;
}

.ui.items > .item.big .description {
  font-size: @h3;
}
