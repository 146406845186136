/*******************************
    User Variable Overrides
*******************************/

.ui.cards > .card > .center.aligned.content {
  text-align: center;
}

.ui.cards > .card > .circular.image {
  background: transparent;
  overflow: hidden;
}

.ui.cards > .card > .circular.image > * {
  -webkit-border-radius: @circularRadius;
  -moz-border-radius: @circularRadius;
  border-radius: @circularRadius;
}

@media only screen and (max-width : (@mobileBreakpoint - 1px)) {
  .ui.six.doubling.cards {
    margin-left: @twoCardOffset;
    margin-right: @twoCardOffset;
  }
  .ui.six.doubling.cards > .card {
    width: @twoCard;
    margin-left: @twoCardSpacing;
    margin-right: @twoCardSpacing;
  }
}

@media only screen and (min-width : @mobileBreakpoint) and (max-width : @largestMobileScreen) {
  .ui.six.doubling.cards {
    margin-left: @threeCardOffset;
    margin-right: @threeCardOffset;
  }
  .ui.six.doubling.cards > .card {
    width: @threeCard;
    margin-left: @threeCardSpacing;
    margin-right: @threeCardSpacing;
  }
}

@media only screen and (min-width : @tabletBreakpoint) and (max-width : @largestTabletScreen) {
  .ui.six.doubling.cards {
    margin-left: @fourCardOffset;
    margin-right: @fourCardOffset;
  }
  .ui.six.doubling.cards > .card {
    width: @fourCard;
    margin-left: @fourCardSpacing;
    margin-right: @fourCardSpacing;
  }
}
